.mainContainer {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    border-width: 1px !important;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 60%;
}

.mainContainer > .card-body {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.testTable {
    width: 100%;
}

.testTable > .row:nth-child(1) {
    text-align: right;
}


.testTable > .row:nth-child(2) {
    background-color: #e6e6e6;
}

.testTable > .row:nth-child(2n + 4) {
    background-color: #f2f2f2;
    visibility: hidden;
}

.testTable > .btn:nth-child(2n) > .row {
    background-color: #e6e6e6;
}

.testTable > .row:nth-child(4n + 5) {
    background-color: #e6e6e6;
}

@media(max-width: 1024px) {
    .mainContainer {
        width: 80%;
    }
}