.rangeCol {
    font-size: 14px;
}

.referenceRangeCard {
    margin-bottom: 20px;
}

.referenceRangeCard > .card-header {
    background-color: #062157;
    color: white;
    text-align: left;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.referenceRangeCard > .card-body {
    text-align: left;
    padding: 0px;
}

.referenceRangeCard > .card-body > .row {
    margin-left: 0px;
    margin-right: 0px;
}