.footer {
    background-color: #062157;
    color: white;
    text-align: left;
    padding-left: 20%;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 30px;
}

.footerEmail {
    color: white;
    text-decoration: underline;
}

.footerEmail:hover {
    color: white;
}

.footerPhone {
    color: white;
}

.footerPhone1 {
    color: white;
}

.footerPhone:hover {
    color: white;
}

.footerTitle {
    color: #d11a47;
    margin-bottom: 2px;
}