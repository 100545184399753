.cptCodeTitle {
    text-align: left;
    margin-left: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
}

.currentPage {
    display: inline;
    margin-right: 10px;
}

.innerAccordionRow {
    text-align: left;
    width: 100%;
    margin: 0px;
}

.pageNumber {
    color: #062157;
    display: inline;
    margin-right: 5px;
    padding: 0px;
    text-decoration: underline;
}

.testCol {
    padding-left: 0px;
    padding-right: 0px;
}

.testCPTCode {
    text-align: left;
    text-decoration: underline;
    margin-left: 40px;
}

.testName {
    text-align: left;
    text-decoration: underline;
}

.testNameTitle {
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
}

.testsHeader {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.testsHeader > .col {
    padding-left: 0px;
}

.testTable {
    color: #062157;
    display: inline-block;
}
