.accordionRow {
    width: 100%;
}

.address {
    text-decoration: underline;
}

.orderBtn {
    background-color: #ececec;
    border-color: black;
    border-radius: 0px;
    border-width: 1px;
    color: #00205B;
    display: block;
    margin-top: 20px;
    margin-left: 20px;
}

.orderBtn:hover {
    background-color: #062157;
    color: white;
}

.referenceBtn {
    background-color: #ececec;
    border-color: black;
    border-radius: 0px;
    border-width: 1px;
    color: #00205B;
    display: block;
    margin-top: 20px;
    margin-left: 20px;
}

.referenceBtn:hover {
    background-color: #062157;
    color: white;
}

.testAttributeTitle {
    margin-top: 10px;
    font-weight: 600;
}

.testAttributeInfo {
    margin-bottom: 10px;
}

.testRow {
    border-radius: 0px;
    display: -ms-flexbox !important;
    display: flex !important;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: auto;
    margin-right: auto;
}

.testRow > .col {
    padding-left: 0px;
}

.testRowBtn {
    width: 100%;
    display: table-cell;
}