.ap-card-body {
    padding: 0;
}

.ap-row {
    margin: 0;
}

.ap-testInfoCard {
    margin: 0;
    width: 100%;
    height: 100%;
}

.specimenPreparation-title {
    font-weight: bold;
    font-size: 1em;
}

.table-head {
    display: flex;
    flex: 1;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    border: 1px #062157 solid;
}

.table-head > div {
    display: flex;
    flex: 1;
    /* padding-left: 10px; */
    padding: 0 10px;
}

.table-body > section {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.table-body > section > div {
    display: flex;
    flex: 1;
    padding-left: 10px;
    border-left: 1px solid black;
    border-top: 1px solid black;
    padding: 1.4em;
    /* min-width: 20%; */
}

.grow {
    min-width: 40%;
}

.small-grow {
    min-width: 15%;

    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

.table-body > section:last-of-type {
    border-bottom: 1px solid black;
}

.table-body > section > div:nth-of-type(even) {
    background: #e6e6e6;
}

.table-body > section > div:last-of-type {
    border-right: 1px solid black;
}

@media only screen and (max-width: 1100px) {
    .ap-ancestor-card-body {
        width: 97vw;
    }

    .ap-grand_parent_body {
        width: 100%;
    }

    .ap-parent-card-body {
        width: 100%;
    }

    .mobile-grow,
    .small-grow {
        min-width: 15%;
    }

    .grow {
        min-width: 29%;
    }

    .mobile-grow,
    .grow {
        -ms-word-break: break-all;
        word-break: break-all;
        word-break: break-word;

        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;
    }
}
