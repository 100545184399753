.usaLogo {
    width: 500px;
}

.header {
    background-color: #ececec;
    padding-left: 15%;
    padding-right: 15%;
}

.headerSearchBar {
    border-radius: 20px;
    flex-grow: 1;
    padding-left: 15px;
    padding-right: 40px;
}

.headerSearchBtn {
    background-color: #062157;
    border-color: #062157;
    border-radius: 20px;
    margin-left: -35px;
}

.headerSearchBtn:hover {
    background-color: #062157;
}

.headerSearchForm {
    flex: 1;
    justify-content: center;
    margin-left: 10px;
}

.navbar {
    padding-left: 5%;
    padding-right: 5%;
}

.searchIcon {
    width: 20px;
    margin-right: -35px;
    z-index: 99 !important;
}

.typeSelect {
    padding: 5px 0px;
    margin-left: 30px;
}

.center-letters {
    display: flex;
    justify-content: space-evenly;
}

@media only screen and (max-width: 1700px) {
    .navbar {
        padding-left: 1%;
        padding-right: 1%;
    }
}

@media only screen and (max-width: 1100px) {
    .navbar {
        display: flex;
        flex-direction: column;
    }

    .navbar > form {
        width: 90%;
    }

    .typeSelect {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 0;
    }

    .typeSelect > label {
        margin-left: 8px !important;
    }
}
