.additionalInformationTitle {
    font-weight: 500;
    text-align: left;
    margin-bottom: 10px;
}

.additionalInformationCard {
    text-align: left;
}

.additionalInformationCard > .card-header {
    background-color: #062157;
    color: white;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.ageIntervalCard {
    margin-bottom: 20px;
}

.ageIntervalTable {
    text-align: left;
}

.ageIntervalTable > thead {
    background-color: #062157;
    color: white;
}

.ageIntervalTable > thead > tr > td, tbody > tr > td {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
}

.card {
    border-radius: 0px;
    border-width: 0px;
}

.cardContainer {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.catalogBtn {
    color: white;
    float: left;
    font-weight: 300;
    text-decoration: underline;
}

/* https://stackoverflow.com/questions/16028519/make-html-floating-elements-take-up-space-inside-div */
.catalogBtn:hover {
    color: #e6e6e6;
}

.cf:before,
.cf:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.cf:after {
    clear: both;
}

.descriptionCard {
    margin-bottom: 20px;
    text-align: left;
}

.descriptionCard > .card-header {
    background-color: #062157;
    color: white;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.logoContainer {
    display: flex;
    flex-direction: row-reverse;
}

.orderingRecommendationsTitle {  
    text-align: left;
    margin-bottom: 10px;
    font-weight: bold;
}

.referenceIntervalTitle {
    font-weight: 500;
    text-align: left;
    margin-bottom: 10px;
}

.referenceIntervalSeperator {
    background-color: solid lightgray;
    width: 100%;
    height: 2px;
}

.testInfoCard {
    color: #062157;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
}

.testInfoCard > .card-header {
    background-color: #062157;
    color: white;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
}

.testInfoCard > .card-body {
    background-color: #e6e6e6;
}

.testTitle {
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}